<template>
    <div
        class="full-width"
        style="position: relative; height: calc(100vh - 125px)"
    >
        <div id="map" style="width: 100vw; height: 100vh"></div>
        <div
            v-if="!map"
            class="full-width"
            style="position: absolute; top: 0; left: 0; height: 100vh"
        >
            <div
                class="column justify-center align-center"
                style="background-color: #e6eeec"
            >
                <!-- <div class="font-32 black--text pb-3">
                    {{ Message }}
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    props: {},
    data() {
        return {
            Message: "Không tải được bản đồ số",
            height: "100vh",
        };
    },
    computed: {
        ...mapGetters("banDo", [
            "map",
            "getLngLatCenter",
            "getLatCenter",
            "getBoundsDiemDung",
            "getIdLayers",
        ]),
        ...mapGetters("chiTietChuyenDi", ["getThongTinXeDangChon"]),
        ...mapGetters("trangChu", ["getCauHinhChung", "getThongTinBenXe"]),
        ...mapGetters("timKiemChuyenDi", [
            "getDialogChiTietChuyenDi",
            "getDialogDsChuyenDi",
            "getDsNamChuyenDiDauTien",
            "getDialogDsChonDiemDen",
            "getTempLayCacChuyenDiDauTien",
        ]),
    },
    methods: {
        ...mapMutations("timKiemChuyenDi", [
            "setGuidNhaXe",
            "set",
            "setTimKiemTheoViTri",
            "setDialogDsChonDiemDen",
            "setDialogChiTietChuyenDi",
            "setDialogDsChuyenDi",
            "setDialogDsChonDiemDen",
        ]),
        ...mapActions("timKiemChuyenDi", [
            "xoaLocTheoDiaDiem",
        ]),
        ...mapMutations("banDo", ["setLatlongtude"]),
        ...mapActions("banDo", [
            "initMap",
            "drawMarker",
            "drawPopoverDiemDen",
            "loadGeoJson",
            "veLoTrinh",
            "veDiemDung",
            "xoaDiemDung",
            "fitBounds",
            "timKiemChuyenDiTheoViTri",
        ]),
        async veLoTrinhTheoXe() {
            // Lộ trình từng xe
            this.veDiemDung();
            try {
                if (!this.getThongTinXeDangChon) {
                    throw new Error("Không có getThongTinXeDangChon");
                }
                //draw
                let geoJson = await this.loadGeoJson(
                    this.getThongTinXeDangChon.loTrinh.googleMapObject
                );
                if (geoJson) {
                    this.veLoTrinh({
                        geoJson: geoJson,
                        idSource: "lo-trinh0",
                        idLayer: 0,
                    });
                }
            } catch (error) {
                this.$stopLoading;
            }
        },
        veNamLoTrinhDauTien() {
            this.veDiemDung();
            this.getDsNamChuyenDiDauTien.forEach(async (e, i) => {
                let geoJson = await this.loadGeoJson(e.loTrinh.googleMapObject);
                if (geoJson) {
                    this.veLoTrinh({
                        geoJson: geoJson,
                        idSource: "lo-trinh" + i,
                        idLayer: i,
                    });
                }
            });
        },
        setCenterMap(val) {
            try {
                if (val) {
                    if (this.getBoundsDiemDung) {
                        this.fitBounds({ bounds: this.getBoundsDiemDung });
                        return;
                    }
                    this.map.panTo([
                        this.getLngLatCenter[0],
                        this.getLngLatCenter[1] - this.getLatCenter,
                    ]);
                } else {
                    this.map.panTo(this.getLngLatCenter);
                }
            } catch (error) {
                console.log(
                    "🚀 ~ file: BanDo.vue ~ line 140 ~ setCenterMap ~ error",
                    error
                );
            }
        },
    },
    async mounted() {
        try {
            this.initMap();
            this.drawMarker();
            let toaDo = this.getThongTinBenXe.viTri.toaDo;
            await this.drawPopoverDiemDen({
                lngLat: [toaDo.longitude, toaDo.latitude],
            });
            //Tìm kiếm chuyến đi trên bản đồ theo vị trí đang chọn
            let self = this;
            this.map.on("click", function (e) {
                //Đóng popup chọn điểm đến thôi
                if (self.getDialogDsChonDiemDen == true) {
                    self.setDialogDsChonDiemDen(false);
                    return;
                }
                let check = false;
                for (let i = 0; i < self.getIdLayers.length; i++) {
                    let features = self.map.queryRenderedFeatures(e.point);
                    //Cho kích vào fill điểm đến
                    if (
                        features.length > 0 &&
                        features[0].layer.type == "fill"
                    ) {
                        break;
                    }
                    //Cho kích vào lộ trình
                    if (features.length > 0 && !features[0].sourceLayer) {
                        check = true;
                        break;
                    }
                }
                if (check) {
                    return;
                }
                //Đóng popup xuống trước khi tìm kiếm theo điểm
                if (self.getDialogDsChuyenDi || self.getDialogDsChuyenDi) {
                    self.setDialogChiTietChuyenDi(false);
                    self.setDialogDsChuyenDi(false);
                    self.setDialogDsChonDiemDen(false);
                    return;
                }
                self.setLatlongtude([e.lngLat.lng, e.lngLat.lat]);
                self.setTimKiemTheoViTri(true);
                self.xoaLocTheoDiaDiem();
                self.timKiemChuyenDiTheoViTri();
            });
        } catch (error) {
            console.log(
                "🚀 ~ file: home.vue ~ line 18 ~ mounted ~ error",
                error
            );
        }
    },
    watch: {
        getThongTinXeDangChon: {
            handler: function () {
                this.veLoTrinhTheoXe();
            },
            deep: true,
        },
        getTempLayCacChuyenDiDauTien: {
            handler: function () {
                this.veNamLoTrinhDauTien();
            },
        },
        getDialogDsChuyenDi: {
            handler: function (val) {
                if (val) {
                    this.setCenterMap(val);
                }
            },
            deep: true,
        },
        getDialogChiTietChuyenDi: {
            handler: function (val) {
                if (val) {
                    this.setCenterMap(val);
                }
            },
            deep: true,
        },
    },
    beforeUnmount() {
        this.setGuidNhaXe("");
    },
};
</script>
<style scoped lang="scss">
::v-deep#map .popover-lotrinh .mapboxgl-popup-content {
    background-color: #000000;
    opacity: 0.92;
}
::v-deep#map .popover-lotrinh .mapboxgl-popup-content:active {
    background-color: #000000;
    opacity: 0.6;
}
::v-deep#map .popover-lotrinh .mapboxgl-popup-tip {
    border-top-color: #000000 !important;
    opacity: 0.9;
}
</style>